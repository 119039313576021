// Bootstrap variale overrides

$body-bg: #f6f7fa;
$body-color: #111;

$theme-colors: (
  "primary": #3273dc,
  "info": #209cee,
  "success": #23d160,
  "warning": #ffdd57,
  "danger": #ff3860
);
