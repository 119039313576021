.SearchFilter {
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 3px;
  padding: 20px;
}

.SearchFilter__Refinement {
  margin-bottom: 10px;
}

.SearchFilter__Refinement .ais-MenuSelect-select {
  width: 100%;
}

.SearchFilter__Refinement .ais-RefinementList-checkbox {
  margin-right: 5px;
}
