.NavbarSearch {
  position: relative;
}

.NavbarSearch .Search__SearchResult {
  position: absolute;
  width: 100%;
  opacity: 1;
  z-index: 999;
}
