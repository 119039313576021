// Bootstrap overrides

.btn:not(.btn-link) {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.form-control:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.nav-tabs {
  box-shadow: 0 8px 13px -6px rgba(0, 0, 0, 0.075);
}

.nav-tabs .nav-item {
  margin-bottom: 0px;
}

/* hack so that selectlist will show above tinymce toolbar */
.form-group .tox .tox-editor-header {
  z-index: 0;
}
