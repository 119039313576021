.Layout {
}

.Layout__Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0px 4px rgba(0, 0, 0, 0.1);
}

.Layout__Main {
}
