.Sidebar {
}

.Sidebar__Sticky {
  position: relative;
  top: 58px; /* Height of navbar */
  height: calc(100vh - 58px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.Sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.Sidebar .nav-link svg {
  margin-right: 6px;
  color: #999;
}

.Sidebar .nav-link.active {
  color: #007bff;
}

.Sidebar .nav-link:hover svg,
.Sidebar .nav-link.active svg {
  color: inherit;
}

.Sidebar__MenuItem_Link {
}

.Sidebar__MenuItem_Link .nav-link {
  padding-right: 0;
}

.Sidebar__MenuItem__Action {
  display: none;
}

.Sidebar__MenuItem__Action .nav-link {
  padding-left: 0;
  padding-right: 0.5rem;
}

.Sidebar__MenuItem__Action:hover {
  display: inline-block;
}

.Sidebar__MenuItem_Link:hover + .Sidebar__MenuItem__Action {
  display: inline-block;
}
